import { RouteObject } from 'react-router-dom';

import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { listingApiFetchInternship } from 'modules/listing/api/internship/listingApiFetchInternship';
import { ViewInternshipPageLoadable } from 'modules/listing/pages/ViewInternship/ViewInternshipPageLoadable';
import { ViewUnpublishedInternshipPageLoadable } from 'modules/listing/pages/ViewUnpublishedInternship/ViewUnpublishedInternshipPageLoadable';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import { AppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import { renderLoader } from 'routing/utils/renderLoader/renderLoader';
import { getUserEnvironment } from 'zustand-stores/userEnvironmentStore';

import { internshipSegmentedRoutes } from './internship.routes';

/**
 * Internship routes
 *
 * These routes are separate in their own file to facilitate maintenance, and
 * they're used on `main.route-objects.tsx`
 */
export const internshipRouteObjects = internshipSegmentedRoutes.map(
  (segmentedRoute) => ({
    path: segmentedRoute.fullPath,
    ...renderLoader({
      loader: async ({ params }: AppRouteLoaderArgs<typeof segmentedRoute>) => {
        const { internshipIdDashSlug = '' } = params;

        const { user } = getUserEnvironment();

        try {
          const [internship] = await Promise.all([
            listingApiFetchInternship(internshipIdDashSlug.split('-')[0]),
            // It's possible this loadable won't be used if the internship is unpublished
            // but in most cases optimistically loading it is best
            ViewInternshipPageLoadable.load(),
          ]);

          switch (internship.type) {
            case 'INTERNSHIP':
              return {
                type: internship.type,
                internship,
                handle: {
                  hideFooterDivider: Boolean(!user),
                  urlByLocale: internship.url,
                } satisfies LayoutHandleProps,
              };
            case 'UNPUBLISHED_INTERNSHIP':
              await ViewUnpublishedInternshipPageLoadable.load();
              return {
                type: internship.type,
                unpublishedInternship: internship,
              };
          }
        } catch {
          return null;
        }
      },
      render: (data) => {
        switch (data?.type) {
          case undefined:
            return <NotFoundPageLoadable noLayout />;
          case 'INTERNSHIP':
            return <ViewInternshipPageLoadable internship={data.internship} />;
          case 'UNPUBLISHED_INTERNSHIP':
            return (
              <ViewUnpublishedInternshipPageLoadable
                unpublishedInternship={data.unpublishedInternship}
              />
            );
        }
      },
    }),
    handle: {
      defaultSearchType: 'INTERNSHIP',
      showDesktopSearch: true,
      hideFooterDivider: true,
    } satisfies LayoutHandleProps,
  }),
) satisfies RouteObject[];
