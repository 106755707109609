import { RouteObject } from 'react-router-dom';

import { ImportedListingPageContainerLoadable } from 'modules/listing/pages/ImportedListing';
import { loadableLoader } from 'routing/utils/loadableLoader';

import { importedListingRoute } from './imported-listing.routes';

export const importedListingRouteObjects = [
  {
    path: importedListingRoute.fullPath,
    loader: loadableLoader(ImportedListingPageContainerLoadable),
    Component: ImportedListingPageContainerLoadable,
  },
] satisfies RouteObject[];
