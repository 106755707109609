import {
  FocusEvent,
  FormEvent,
  KeyboardEvent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Autosuggest, {
  ChangeEvent,
  RenderInputComponentProps,
  RenderSuggestionsContainerParams,
} from 'react-autosuggest';

import { AutosuggestWrapper } from 'components/Autocomplete/Autocomplete.styled';
import {
  InputAffixVariant,
  InputStyleVariant,
} from 'components/Input/Input.types';
import { LoadingSection } from 'components/LoadingSection/LoadingSection';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { REMOTE_KEYWORDS } from 'utils/constants/search';
import { setGMapsError, useGMapsErrored } from 'zustand-stores/gMapsErrorStore';

import { LocationInputSuggestion } from './LocationInputSuggestion';
import { LocationSuggestion } from './LocationSuggestion';
import { StyledInput } from './StyledInput';
import { SuggestionsContainer } from './SuggestionsContainer';
import { geocoderResultToSearchLocation } from './geocoderResultToSearchLocation';
import { getPlacePredictions } from './getPlacePredictions';
import { getRemoteOptions } from './getRemoteOptions';

const defaultKeyword = REMOTE_KEYWORDS[CURRENT_LOCALE][0];

type Props = {
  onChange: (searchLocation: SearchLocation | undefined) => void;
  onClear?: () => void;
  hasSuccess?: boolean;
  'data-qa-id'?: string;
  placeholder?: string;
  required?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  showLocationIcon?: boolean;
  showClearButton?: boolean;
  className?: string;
  handlePlaceDetails?: (place: google.maps.GeocoderResult) => void;
  addressTypes?: Array<string>;
  includeCity?: boolean;
  includeFull?: boolean;
  onBlur?: (event: FocusEvent<HTMLElement>) => void;
  disabled?: boolean;
  hasRemoteOptions?: boolean;
  highlightFirstSuggestion?: boolean;
  componentRestrictions?: google.maps.places.ComponentRestrictions;
  affixVariant?: InputAffixVariant;
  styleVariant?: InputStyleVariant;
  locationText: string | undefined | null;
};

export function LocationInput({
  hasSuccess,
  onChange,
  onClear,
  placeholder,
  required,
  inputRef: customInputRef,
  showLocationIcon = false,
  showClearButton,
  className,
  addressTypes,
  includeCity,
  includeFull,
  disabled,
  highlightFirstSuggestion = true,
  componentRestrictions,
  hasRemoteOptions,
  locationText,
  onBlur,
  handlePlaceDetails: handlePlaceDetailsProp,
  'data-qa-id': dataQaId,
  affixVariant,
  styleVariant,
}: Props) {
  const defaultInputRef = useRef<HTMLInputElement>(null);
  const inputRef = customInputRef || defaultInputRef;

  const [loading, setLoading] = useState(false);

  // This is used to keep the input state if typed before the app has fully loaded
  const [backupState, setBackupState] = useState('');

  const { gMapsErrored } = useGMapsErrored();
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService>();
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();
  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>();

  const [locationInputValue, setLocationInputValue] = useState(
    locationText || '',
  );
  const [prevPropsLocationText, setPrevPropsLocationText] =
    useState(locationText);

  useEffect(() => {
    if (locationText !== prevPropsLocationText) {
      setLocationInputValue(locationText || '');
      setPrevPropsLocationText(locationText);
    }
  }, [locationText, prevPropsLocationText]);

  const clearLocation = useCallback(() => {
    setLocationInputValue('');
    setBackupState('');

    if (onClear) onClear();

    onChange(undefined);
  }, [onChange, onClear]);

  const onLocationInputValueChange = (
    _: FormEvent<unknown>,
    { newValue }: ChangeEvent,
  ) => {
    if (newValue === '') {
      clearLocation();
    }

    setLocationInputValue(newValue);
  };

  const onBlurInternal = (e: FocusEvent<HTMLElement>) => {
    if (onBlur) {
      return onBlur(e);
    }

    if (locationInputValue && locationInputValue !== locationText) {
      clearLocation();
    }
  };

  const loadGoogleMapsAutocomplete = useCallback(async () => {
    if (autocompleteService) return;

    setLoading(true);

    try {
      const { AutocompleteService, AutocompleteSessionToken } =
        (await google.maps.importLibrary(
          'places',
        )) as google.maps.PlacesLibrary;
      const { Geocoder } = (await google.maps.importLibrary(
        'geocoding',
      )) as google.maps.GeocodingLibrary;
      setAutocompleteService(new AutocompleteService());
      setGeocoder(new Geocoder());
      setSessionToken(new AutocompleteSessionToken());
    } catch (error) {
      if (error instanceof Error) {
        setGMapsError(error.message);
      }
    }
  }, [autocompleteService]);

  const handlePlaceDetails = useCallback(
    (place: google.maps.GeocoderResult) => {
      if (handlePlaceDetailsProp) {
        handlePlaceDetailsProp(place);
        return;
      }

      return geocoderResultToSearchLocation(place, {
        includeCity,
        includeFull,
      }).then(onChange);
    },
    [handlePlaceDetailsProp, includeCity, includeFull, onChange],
  );

  const onSuggestionSelected = useCallback(
    async (
      _: FormEvent<HTMLInputElement>,
      { suggestion }: { suggestion: LocationSuggestion },
    ) => {
      if (!geocoder) return;

      if (suggestion.isRemoteOption) {
        onChange({
          text: null,
          stateCode: null,
          countryCode: null,
          boundingBoxN: null,
          boundingBoxW: null,
          boundingBoxS: null,
          boundingBoxE: null,
          geoType: null,
          latitude: null,
          longitude: null,
          isRemote: true,
          timezone: null,
        });

        return;
      }

      await geocoder.geocode(
        {
          placeId: suggestion.place_id,
          language: CURRENT_LOCALE,
        },
        (results) => {
          if (results?.length) handlePlaceDetails(results[0]);
        },
      );
    },
    [geocoder, handlePlaceDetails, onChange],
  );

  const [suggestions, setSuggestions] = useState<LocationSuggestion[] | null>(
    null,
  );

  useEffect(() => {
    if (
      inputRef.current &&
      document.activeElement === inputRef.current &&
      !autocompleteService
    ) {
      setBackupState(inputRef.current.value);
    }
  }, [autocompleteService, inputRef]);

  const [inProgressQuery, setInProgressQuery] = useState<
    | Promise<
        ReadonlyArray<{
          name: string;
          value: string;
        }>
      >
    | null
    | undefined
  >(null);

  const loadSuggestions = useCallback(
    (value: string) => {
      // Cancel the previous request
      if (inProgressQuery) {
        inProgressQuery.then(() => {
          setTimeout(() => loadSuggestions(value), 200);
        });
        return undefined;
      }

      if (!autocompleteService || !sessionToken) return;

      const query = getPlacePredictions({
        autocompleteService,
        input: value,
        componentRestrictions,
        addressTypes,
        sessionToken,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const remoteOptions: any = [];

      if (hasRemoteOptions) {
        remoteOptions.push(getRemoteOptions({ value, defaultKeyword }));
      }

      query.then((options) => {
        // @ts-expect-error TS(2488): Type 'unknown' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
        setSuggestions([...remoteOptions, ...options].filter(Boolean));
        setInProgressQuery(null);
        setLoading(false);
        if (backupState) {
          setLocationInputValue(backupState);
        }
      });

      // @ts-expect-error TS(2345): Argument of type 'Promise<unknown>' is not assigna... Remove this comment to see the full error message
      setInProgressQuery(query);
      return query;
    },
    [
      addressTypes,
      autocompleteService,
      backupState,
      componentRestrictions,
      hasRemoteOptions,
      inProgressQuery,
      sessionToken,
    ],
  );

  const onSuggestionsFetchRequested = useCallback(
    ({ value }: { value: string }) => {
      if (autocompleteService) {
        loadSuggestions(value);
      }
    },
    [autocompleteService, loadSuggestions],
  );

  const renderSuggestion = useCallback(
    (suggestion: LocationSuggestion) => (
      <LocationInputSuggestion suggestion={suggestion} />
    ),
    [],
  );

  const onSuggestionsClearRequested = useCallback(() => {
    if (suggestions) {
      setSuggestions([]);
    }
    setInProgressQuery(null);
  }, [suggestions]);

  useEffect(() => {
    if (
      !autocompleteService &&
      inputRef.current === document.activeElement &&
      backupState
    ) {
      loadGoogleMapsAutocomplete();
    } else if (
      inputRef.current === document.activeElement &&
      backupState &&
      !suggestions
    ) {
      loadSuggestions(backupState);
    } else if (
      inputRef.current === document.activeElement &&
      locationInputValue &&
      !suggestions
    ) {
      loadSuggestions(locationInputValue);
    }
  }, [
    autocompleteService,
    backupState,
    loadGoogleMapsAutocomplete,
    loadSuggestions,
    locationInputValue,
    suggestions,
    inputRef,
  ]);

  const renderSuggestionsContainer = ({
    containerProps,
    children,
  }: RenderSuggestionsContainerParams) => {
    if (!children && (!loading || (loading && !locationInputValue)))
      return null;

    return (
      <SuggestionsContainer containerProps={containerProps} loading={loading}>
        {loading && locationInputValue ? <LoadingSection /> : children}
      </SuggestionsContainer>
    );
  };

  const renderInputComponent = useCallback(
    ({
      autoComplete,
      className: inputClassName,
      disabled: inputDisabled,
      onBlur: inputOnBlur,
      onChange: inputOnChange,
      onFocus,
      onKeyDown,
      onKeyPress,
      ref,
      title,
      value,
      ...renderInputComponentProps
    }: RenderInputComponentProps) => (
      <StyledInput
        aria-activedescendant={
          renderInputComponentProps['aria-activedescendant']
        }
        ref={ref}
        aria-autocomplete={renderInputComponentProps['aria-autocomplete']}
        aria-controls={renderInputComponentProps['aria-controls']}
        aria-label={getText('Location')}
        autoComplete={autoComplete}
        className={inputClassName}
        disabled={inputDisabled}
        onBlur={inputOnBlur}
        onChange={inputOnChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        hasSuccess={hasSuccess}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        required={required}
        title={title}
        value={value}
        onClear={showClearButton ? clearLocation : null}
        gMapsErrored={gMapsErrored}
        showLocationIcon={showLocationIcon}
        styleVariant={styleVariant}
        affixVariant={affixVariant}
        id={dataQaId || 'location-input'}
        data-qa-id={dataQaId || 'location-input'}
      />
    ),
    [
      clearLocation,
      gMapsErrored,
      hasSuccess,
      placeholder,
      required,
      dataQaId,
      affixVariant,
      styleVariant,
      showClearButton,
      showLocationIcon,
    ],
  );

  const onKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }, []);

  const getSuggestionValue = useCallback(
    (suggestion: LocationSuggestion) => suggestion.description,
    [],
  );

  return (
    <AutosuggestWrapper>
      <Autosuggest
        highlightFirstSuggestion={highlightFirstSuggestion}
        suggestions={suggestions || []}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        onSuggestionSelected={onSuggestionSelected}
        shouldRenderSuggestions={(v) =>
          Boolean(v && v.toString().trim().length >= 2)
        }
        renderInputComponent={renderInputComponent}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        inputProps={{
          className,
          disabled,
          onChange: onLocationInputValueChange,
          onBlur: onBlurInternal,
          onFocus: loadGoogleMapsAutocomplete,
          onKeyPress,
          placeholder,
          required,
          value: locationInputValue || backupState || '',
          title: getText('Location'),
          ref: inputRef,
        }}
      />
    </AutosuggestWrapper>
  );
}
