import { RouteObject } from 'react-router-dom';

import { NotFoundContent } from 'components/NotFoundContent/NotFoundContent';
import { LayoutCenteredBody } from 'layouts/Layout/LayoutCenteredBody';
import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { OrgDashboardCheckoutCartPageLoadable } from 'modules/orgDashboard/checkout/pages/Cart/OrgDashboardCheckoutCartPageLoadable';
import { OrgDashboardOrgContextConsumerWaitUntilLoaded } from 'modules/orgDashboard/containers/OrgDashboardOrgContextConsumerWaitUntilLoaded';
import { OrgDashboardCreateEventPageContainerLoadable } from 'modules/orgDashboard/pages/CreateEvent/OrgDashboardCreateEventPageContainerLoadable';
import { CreateJobPageContainerLoadable } from 'modules/orgDashboard/pages/CreateJob/CreateJobPageContainerLoadable';
import { OrgDashboardCreateVolopPageContainerLoadable } from 'modules/orgDashboard/pages/CreateVolop/OrgDashboardCreateVolopPageContainerLoadable';
import { OrgDashboardPostAListingPageContainerLoadable } from 'modules/orgDashboard/pages/PostAListing/OrgDashboardPostAListingPageContainerLoadable';

import {
  orgDashboardCheckoutCartRoute,
  orgDashboardCreateEventRoute,
  orgDashboardCreateInternshipRoute,
  orgDashboardCreateJobRoute,
  orgDashboardCreateVolopRoute,
  orgDashboardPostAListingRoute,
} from './orgDashboard.routes';

export const noSubLayoutBaseOrgDashboardRouteObjects = [
  {
    handle: { darkBackground: true } satisfies LayoutHandleProps,
    path: orgDashboardPostAListingRoute.relativePath,
    element: (
      <OrgDashboardOrgContextConsumerWaitUntilLoaded>
        {(org) => <OrgDashboardPostAListingPageContainerLoadable org={org} />}
      </OrgDashboardOrgContextConsumerWaitUntilLoaded>
    ),
  },
  {
    handle: { darkBackground: true } satisfies LayoutHandleProps,
    path: orgDashboardCreateJobRoute.relativePath,
    element: (
      <LayoutCenteredBody>
        <OrgDashboardOrgContextConsumerWaitUntilLoaded>
          {(org) => (
            <CreateJobPageContainerLoadable org={org} listingType="JOB" />
          )}
        </OrgDashboardOrgContextConsumerWaitUntilLoaded>
      </LayoutCenteredBody>
    ),
  },
  {
    handle: { darkBackground: true } satisfies LayoutHandleProps,
    path: orgDashboardCreateInternshipRoute.relativePath,
    element: (
      <LayoutCenteredBody>
        <OrgDashboardOrgContextConsumerWaitUntilLoaded>
          {(org) => (
            <CreateJobPageContainerLoadable
              org={org}
              listingType="INTERNSHIP"
            />
          )}
        </OrgDashboardOrgContextConsumerWaitUntilLoaded>
      </LayoutCenteredBody>
    ),
  },
  {
    handle: { darkBackground: true } satisfies LayoutHandleProps,
    path: orgDashboardCreateVolopRoute.relativePath,
    element: (
      <LayoutCenteredBody>
        <OrgDashboardOrgContextConsumerWaitUntilLoaded>
          {(org) => <OrgDashboardCreateVolopPageContainerLoadable org={org} />}
        </OrgDashboardOrgContextConsumerWaitUntilLoaded>
      </LayoutCenteredBody>
    ),
  },
  {
    handle: { darkBackground: true } satisfies LayoutHandleProps,
    path: orgDashboardCreateEventRoute.relativePath,
    element: (
      <LayoutCenteredBody>
        <OrgDashboardOrgContextConsumerWaitUntilLoaded>
          {(org) => <OrgDashboardCreateEventPageContainerLoadable org={org} />}
        </OrgDashboardOrgContextConsumerWaitUntilLoaded>
      </LayoutCenteredBody>
    ),
  },
  {
    path: orgDashboardCheckoutCartRoute.relativePath,
    element: (
      <OrgDashboardOrgContextConsumerWaitUntilLoaded>
        {(org) => (
          <OrgDashboardCheckoutCartPageLoadable
            // Remount component when org changes to ensure all fields
            // are reset
            key={org.id}
            apiOrg={org}
          />
        )}
      </OrgDashboardOrgContextConsumerWaitUntilLoaded>
    ),
  },
  {
    path: '*',
    element: <NotFoundContent textOnly />,
  },
] satisfies RouteObject[];
